/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
import styled from "@emotion/styled"
import NoHeaderLayout from "./noheader-layout"

import Header from "./header"
import "./layout.css"

const Content = styled.div`
  margin: 0 auto;
  max-width: 860px;
  padding: 0 1.0875rem 1rem;
  padding-top: 0;
`

const Layout = ({ children }) => (
<NoHeaderLayout>        
  <Header showHomeLink={true} />
  <Content>
    <main>{children}</main>
  </Content>
  </NoHeaderLayout>)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
